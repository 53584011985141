import { BehaviorSubject, Subject } from 'rxjs';

import { HeaderItem } from './models/headerItem.model';
import { DropdownMenuItem } from './models/dropdownMenuItem.model';

class SbHeaderService {
    private _items: HeaderItem[];
    private _dropdownMenuItems: DropdownMenuItem[];
    private _selectedTabName: string;

    private preferredNameSubject = new BehaviorSubject<string>(null);
    private selectedDashboardNameSubject = new BehaviorSubject<string>(null);
    private avatarSubject = new BehaviorSubject<boolean>(null);
    private simplifiedPopupSubject = new BehaviorSubject<boolean>(false);
    private dropdownMenuSubject = new BehaviorSubject<DropdownMenuItem[]>(null);
    private menuSubject = new BehaviorSubject<any[]>(null);
    private menuItemClickedSubject = new Subject<any[]>();
    private quickLinksSubject = new BehaviorSubject<any[]>(null);
    private dashboardLinksSubject = new BehaviorSubject<any[]>(null);
    private tabSelectionSubject = new BehaviorSubject<any[]>(null);
    private addTabSubject = new BehaviorSubject<any[]>(null);
    private hashCodeSubject = new BehaviorSubject<string>(null);
    private signOutSubject = new Subject<void>();

    constructor() {
        this._items = [];
        this._dropdownMenuItems = [];
        this._selectedTabName = null;
    }

    add(item: HeaderItem) {
        if (item.label && !this._items.some(e => (e.label === item.label))) {
            this._items.push(item);
        }
    }

    items(){
        return this._items;
    }

    preferredName(){ return this.preferredNameSubject; }

    selectedDashboardName(){ return this.selectedDashboardNameSubject; }

    avatar(){ return this.avatarSubject; }

    simplifiedPopup(){ return this.simplifiedPopupSubject; }

    hashCode(){ return this.hashCodeSubject; }

    dropdownMenu(){
        return {
            add: (i: DropdownMenuItem) => {
                if(this._dropdownMenuItems.filter(v => v.label === i.label).length !== 0){
                    return;
                }
                this._dropdownMenuItems.push(i);
                this._dropdownMenuItems.sort((a, b) => a.index - b.index);
                this.dropdownMenuSubject.next(this._dropdownMenuItems);
            },
            remove: (i: DropdownMenuItem) => {
                this._dropdownMenuItems = this._dropdownMenuItems.filter(v => v.label !== i.label);
                this.dropdownMenuSubject.next(this._dropdownMenuItems);
            },
            observable: this.dropdownMenuSubject
        }
    }

    menu(){
        return {
            itemClicked: this.menuItemClickedSubject,
            items: this.menuSubject
        };
    }

    quickLinks(){
        return this.quickLinksSubject;
    }

    dashboardLinks(){
        return this.dashboardLinksSubject;
    }

    tabs(){
        return {
            add: this.addTabSubject,
            setSelected: (selected: string) => {
                this._selectedTabName = selected;
            },
            getSelected: () => {
                return this._selectedTabName;
            },
            select: this.tabSelectionSubject,
            isInPersonalTab: (): boolean => {
                return this._selectedTabName === null ? null :
                    this._selectedTabName === 'My Personal Banking';
            },
            isInBusinessTab: (): boolean => {
                return this._selectedTabName === null ? null :
                    this._selectedTabName === 'My Business Banking';
            }
        };

    }

    signOut(){
        return this.signOutSubject;
    }

}

declare global {
    interface Window {
        sbHeader: SbHeaderService;
    }
}

const sbHeader = window.sbHeader = window.sbHeader || new SbHeaderService();

export {
    sbHeader,
    HeaderItem,
    DropdownMenuItem
};